import React from "react";

const Header1 = ({ children, className }) => (
    <h1
        className={`${className} text-outragous-orange text-4xl lg:text-5xl font-normal leading-8 mb-1`}
    >
        {children}
    </h1>
);

export default Header1;
